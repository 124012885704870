import { marked } from 'marked';
import { RichText, RichTextType } from '../models/RichText';
import sanitizeHtml from 'sanitize-html';
import FileService from '../services/FileService';
import imagePlaceholderLoader from '../assets/images/skeleton-screen-animation.gif';

const fullAllowedHtmlTags = {
  p: ['style'],
  h1: [],
  h2: [],
  h3: [],
  h4: [],
  h5: [],
  h6: [],
  strong: [],
  em: [],
  u: [],
  s: [],
  a: ['href'],
  img: ['src', 'alt', 'class', 'data-file-id'],
  ul: ['style'],
  ol: ['style'],
  li: [],
  blockquote: [],
  code: [],
  pre: [],
  br: [],
  hr: [],
  table: ['style'],
  thead: [],
  tbody: [],
  tfoot: [],
  tr: [],
  th: ['style', 'colspan', 'rowspan', 'class'],
  td: ['style', 'colspan', 'rowspan', 'class'],
  span: ['class'],
};

const svgAllowedTags = {
  p: [],
  span: ['contenteditable', 'data-placeholder', 'data-placeholder-delete', 'class', 'style', 'data-is-answer', 'data-loader'],
  svg: ['xmlns', 'viewBox', 'width', 'height', 'class', 'fill', 'data-cy', 'style', 'id', 'stroke-width', 'stroke-linecap'],
  path: ['d', 'fill', 'class', 'stroke', 'stroke-width', 'stroke-linecap', 'stroke-linejoin', 'fill-rule', 'clip-rule', 'id'],
  circle: ['cx', 'cy', 'r', 'fill', 'class', 'stroke', 'id'],
  ellipse: ['cx', 'cy', 'rx', 'ry', 'fill', 'class'],
};

export const convertToRichText = (input: string | RichText): RichText | null => {
  if (typeof input === 'string') {
    input = input.replace(/\\n/g, '<br />');
    const html = marked(input, { async: false, breaks: true }) as string;
    return {
      type: RichTextType.HTML,
      value: html,
    };
  } else if (
    typeof input === 'object' &&
    input !== null &&
    'type' in input &&
    typeof input.type === 'number' &&
    'value' in input &&
    typeof input.value === 'string' &&
    Object.keys(input).length === 2
  ) {
    return input as RichText;
  }

  return null;
};

export const sanitizeHTML = (html: string, allowPlaceholders: boolean = true, allowFullHTML: boolean = false, allowHTML: boolean = true): string => {
  const tags = allowHTML
    ? {
        ...(allowFullHTML ? fullAllowedHtmlTags : {}),
        ...(allowPlaceholders ? svgAllowedTags : {}),
      }
    : {};

  let result = sanitizeHtml(html, {
    allowedTags: Object.keys(tags),
    allowedAttributes: tags,
    parser: {
      lowerCaseTags: false,
      lowerCaseAttributeNames: false,
    },
  });

  if (allowHTML) {
    result = result.replaceAll('\u00A0', '&nbsp;');
  }

  return result;
};

export const plainText = (html: string): string => {
  const sanitized = sanitizeHTML(html, false, false, false);
  return decodeHtmlEntities(sanitized);
};

const decodeHtmlEntities = (input: string): string => {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = input;
  return textArea.value;
};

export const sanitizePastedHTML = (html: string): string => {
  return sanitizeHtml(html, {
    allowedTags: Object.keys(fullAllowedHtmlTags),
    allowedAttributes: fullAllowedHtmlTags,
  });
};

export const imageLoader = (image?: HTMLImageElement, fileId?: string | null): Promise<string> => {
  if (!fileId && image) {
    image.setAttribute('src', imagePlaceholderLoader);
    fileId = image.getAttribute('data-file-id');
  }

  if (fileId) {
    return FileService.getFile(fileId)
      .then((fileBlob: Blob) => {
        const imageUrl = URL.createObjectURL(fileBlob);
        if (image) {
          image.setAttribute('src', imageUrl);
        }
        return imageUrl;
      })
      .catch((error) => {
        console.error('Error loading image:', error);
        return '';
      });
  }

  return Promise.resolve('');
};
