import { yesNoComparisonOptions } from './../form-builder/ConditionGenerator';
import { TFunction, Namespace } from 'i18next';
import { FormConfig } from '../../models/Form';
import jp from 'jsonpath';
import { Action } from '../form-builder/FormBuilderTypes';
import ActionTypes from './ActionTypes';
import LanguageUtils from '../../utils/LanguageUtils';
import { RiskRating } from '../../utils/RiskUtils';
import { RiskRatingKeys } from '../../models/Risk';
import { CustomSourceKeyAssociciatedClients } from '../../models/Picklist';
import ClientService from '../../services/ClientService';
import PickListService from '../../services/PickListService';

const actionIdRegex = /^([a-fA-F0-9]{8}[-]?([a-fA-F0-9]{4}[-]?){3}[a-fA-F0-9]{12})(\$.*)/;

const evaluate = (input: string, form: FormConfig): boolean => {
  if (input === 'false') {
    return false;
  } else if (input === 'true') {
    return true;
  }

  const parts = input.split(' ');
  let toEval: string[] = [];

  for (let part of parts) {
    part = part.trim();

    const keyMatch = part.match(actionIdRegex);
    if (keyMatch) {
      const actionId = keyMatch[1];
      const path = keyMatch[3];

      const actionResponse = form.answers[actionId];
      if (!actionResponse) {
        toEval.push('false');
        continue;
      }

      if (typeof actionResponse === 'string') {
        if (path === '$.length') {
          toEval.push(actionResponse.length.toString());
        } else {
          toEval.push(actionResponse);
        }
      } else if (typeof actionResponse === 'object') {
        if (Array.isArray(actionResponse)) {
          toEval.push(jp.query(actionResponse, path).join('|'));
        } else {
          toEval.push(jp.value(actionResponse, path));
        }
      } else {
        console.warn('Unknown type ', typeof actionResponse, 'not evaluating');
      }
    } else if ((part.startsWith('"') && part.endsWith('"')) || (part.startsWith("'") && part.endsWith("'"))) {
      toEval.push(part.substr(1, part.length - 2));
    } else {
      toEval.push(part);
    }
  }

  if (toEval.length === 1) {
    if (Array.isArray(toEval[0])) {
      return toEval[0].length > 0;
    } else if (typeof toEval[0] === 'object') {
      return Object.keys(toEval[0]).length > 0;
    } else {
      const value = toEval[0].toLowerCase();
      if (value === 'true') {
        return true;
      } else if (value === 'false') {
        return false;
      } else {
        return !!toEval[0];
      }
    }
  }

  if (toEval.length !== 3) {
    console.warn('Not 3 parts while evaluation condition!', toEval);
    toEval = toEval.slice(0, 3);
  }

  const left = toEval[0]?.toString();
  const condition = toEval[1]?.toString();
  const right = toEval[2]?.toString();

  switch (condition) {
    case '=':
      return left == right;
    case '!=':
      return left != right;
    case '<':
      return parseInt(left, 10) < parseInt(right, 10);
    case '<=':
      return parseInt(left, 10) <= parseInt(right, 10);
    case '>':
      return parseInt(left, 10) > parseInt(right, 10);
    case '>=':
      return parseInt(left, 10) >= parseInt(right, 10);
    case 'CONTAINS':
      if (left === 'false') return false;
      return !!left.split('|').find((x) => x === right);
    case '!CONTAINS':
      if (left === 'false') return false;
      return !left.split('|').find((x) => x === right);
    default:
      console.warn(`Unknown action ${toEval[1]} while comparing values`);
      return false;
  }
};

const or = (parts: boolean[]): boolean => {
  let value = false;
  for (const part of parts) {
    value = value || part;
  }

  return value;
};

const and = (parts: boolean[]): boolean => {
  let value = true;
  for (const part of parts) {
    value = value && part;
  }

  return value;
};

const EvaluationEngine = (input: string, form: FormConfig): boolean | string => {
  let trueOutput: boolean | string = true;
  let falseOutput: boolean | string = false;
  if (input.indexOf(' -> ') > -1) {
    const split = input.split(' -> ');
    input = split[0];
    [trueOutput, falseOutput] = split[1].split(',');
  }

  const result = or(input.split(/\sor\s/im).map((orParts) => and(orParts.split(/\sand\s/im).map((part) => evaluate(part.trim(), form)))));

  return result ? trueOutput : falseOutput;
};

export const conditionToText = async (
  input: string,
  clientId: string,
  riskRating: string,
  action: Action,
  t: TFunction<Namespace>,
): Promise<string> => {
  if (!input) return '';

  const [_, comparitor, comparitorValue] = input.split(' ');

  const getComparitorValueText = async (comparitorValue: string, action: Action, t: TFunction<Namespace>): Promise<string> => {
    if (action.type === 'YesNoAction' && comparitorValue) {
      const yesNoOption = yesNoComparisonOptions(t).find((x) => x.id === comparitorValue.replaceAll(`'`, ''));
      return yesNoOption?.text || comparitorValue;
    } else if (action.type === 'PickListAction' || action.type === 'SelectListAction') {
      const { sourceType } = action.data;

      const getAffiliateName = async (): Promise<string> => {
        const res = await ClientService.getAffiliates(clientId);
        const selectedItem = res.data.find((x) => x.id === comparitorValue.replaceAll(`'`, ''));
        return selectedItem?.name || comparitorValue;
      };

      const getPickListItemName = async (): Promise<string> => {
        const res = await PickListService.getPickList(sourceType);
        const selectedItem = res.data.items.find((x) => x.id === comparitorValue.replaceAll(`'`, ''));
        return LanguageUtils.getTranslation('name', selectedItem?.translations || {}) || comparitorValue;
      };

      return sourceType === CustomSourceKeyAssociciatedClients ? await getAffiliateName() : await getPickListItemName();
    }

    return comparitorValue;
  };

  const comparitorValueText = await getComparitorValueText(comparitorValue, action, t);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editorConditional = ActionTypes[action.type]?.editorConditionals?.find((x: any) => x.operation === comparitor);
  const conditional = editorConditional ? t(`form-builder:${editorConditional.friendlyName}`) : '';

  const actionTitle =
    LanguageUtils.getTranslation('question', action.translations || {}) || LanguageUtils.getTranslation('title', action.translations || {});

  return t('risk:calculated-risk-text', {
    action: actionTitle,
    conditional: conditional.toLowerCase(),
    value: comparitorValueText,
    risk: riskRating,
  });
};

export default EvaluationEngine;
