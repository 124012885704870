import { createContext, useContext } from 'react';
import { FormRendererMode } from './FormRendererDesignContextTypes';
import { FormSection } from '../models/Form';

export type FormSectionRendererContextType = {
  mode: FormRendererMode;
  step: FormSection;
};

export const EmptyFormSectionRendererInfoValues: FormSectionRendererContextType = {
  mode: FormRendererMode.EditView,
  step: null!,
};

export const FormSectionRendererInfoContext = createContext<FormSectionRendererContextType>(EmptyFormSectionRendererInfoValues);
export const useFormSectionRendererInfo = (): FormSectionRendererContextType => useContext(FormSectionRendererInfoContext);
