/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, FC, useCallback, useMemo } from 'react';
import { FormActionContext } from '../../../contexts/FormActionContext';
import { useFormRendererInfo } from '../../../contexts/FormRendererContext';
import { FormRendererDesignContext, useFormRendererDesign } from '../../../contexts/FormRendererDesignContext';
import { FormRendererMode } from '../../../contexts/FormRendererDesignContextTypes';
import { useFormSectionRendererInfo } from '../../../contexts/FormSectionRendererContext';
import { FormAction, FormSection } from '../../../models/Form';
import ObjectUtils from '../../../utils/ObjectUtils';
import ActionTypes from '../ActionTypes';
import LanguageUtils from '../../../utils/LanguageUtils';

type FormActionRendererV2Props = {
  step: FormSection;
  action: FormAction;
  isRequired: boolean;
  isLast: boolean;
};

const FormActionRendererV2: FC<FormActionRendererV2Props> = (props) => {
  const { step, action, isRequired, isLast } = props;

  const {
    onAnswer,
    setQuestionValid,
    actionRisks,
    validQuestions,
    questionAnswers,
    language,
    readOnly,
    answersBusySaving,
    createPlaceholder,
    deletePlaceholder,
  } = useFormRendererInfo();
  const { mode } = useFormSectionRendererInfo();

  const { actionWrapper } = useFormRendererDesign();

  const data = useMemo(() => {
    // Apply translations ontop of data element
    const translatedData = LanguageUtils.getActionDataTranslation(ObjectUtils.DeepClone(action), language);

    // Fix inconsistancies with question/title usage
    return {
      ...translatedData,
      question: translatedData?.question || translatedData?.title,
      buttonText: translatedData.buttonText || '',
    };
  }, [action, language]);

  const component = useMemo(() => {
    if (!action.type) {
      return;
    }

    switch (mode) {
      case FormRendererMode.EditView:
      default:
        return ActionTypes[action.type].editAction;
      case FormRendererMode.PreviewView:
        return ActionTypes[action.type].previewAction;
    }
  }, [action.type, mode]);

  const onAnswerInternal = useCallback((answer: any) => onAnswer(action.id, answer), [action.id, onAnswer]);
  const onValidInternal = useCallback((valid: any) => setQuestionValid(action.id, valid), [action.id, setQuestionValid]);
  const deletePlaceholderInternal = useCallback((value: string) => deletePlaceholder(action.id, value), [action.id, deletePlaceholder]);

  const busySavingAnswer = useMemo(() => {
    // We want this to be answersBusySaving[action.id], so that we don't disable the entire form
    // on one's answer, but that caused issues with conditionals on the BE, so sticking to doing that
    // for now

    return Object.values(answersBusySaving).some((x) => x);
  }, [answersBusySaving]);

  const ActionWrapperComponent = ActionTypes[action.type].customActionWrapper ?? actionWrapper;

  return (
    <FormRendererDesignContext.Consumer>
      {(designValue) => (
        <FormRendererDesignContext.Provider value={{ ...designValue, slots: {} }}>
          <FormActionContext.Provider
            key={action.id}
            value={{
              currentSection: step,
              currentAction: { ...action, data: data },
              actionValid: validQuestions[action.id],
              riskLevel: actionRisks?.[action.id] ?? 0,
              readOnly: readOnly || busySavingAnswer,
              busySavingAction: answersBusySaving[action.id],
              onAnswer: onAnswerInternal,
              onValid: onValidInternal,
              createPlaceholder,
              deletePlaceholder: deletePlaceholderInternal,
            }}
          >
            {createElement(
              ActionWrapperComponent,
              typeof ActionWrapperComponent === 'string' ? undefined : { isLast },
              component &&
                createElement(component, {
                  id: action.id,
                  data,
                  response: questionAnswers[action.id],
                  required: isRequired,
                }),
            )}
          </FormActionContext.Provider>
        </FormRendererDesignContext.Provider>
      )}
    </FormRendererDesignContext.Consumer>
  );
};
export default FormActionRendererV2;
