import { useEffect, useRef } from 'react';
import { FCWithChildren } from '../../types/FCWithChildren';

const ellipse = (parentNode: HTMLElement, childNode: HTMLElement, txtNode: HTMLElement) => {
  const childWidth = childNode.offsetWidth;
  const containerWidth = parentNode.offsetWidth;
  const txtWidth = txtNode.offsetWidth;
  const targetWidth = childWidth > txtWidth ? childWidth : txtWidth;

  if (targetWidth > containerWidth) {
    const str = txtNode.textContent || '';
    const txtChars = str.length;
    const avgLetterSize = txtWidth / txtChars;
    const canFit = (containerWidth - (targetWidth - txtWidth)) / avgLetterSize;
    const delEachSide = (txtChars - canFit + 5) / 2;
    const endLeft = Math.floor(txtChars / 2 - delEachSide);
    const startRight = Math.ceil(txtChars / 2 + delEachSide);

    txtNode.setAttribute('data-original', txtNode.textContent || '');
    txtNode.textContent = str.substring(0, endLeft) + '...' + str.substring(startRight);
  }
};

const MiddleTruncate: FCWithChildren<{ width?: string }> = (props) => {
  const measuredParent = useRef<HTMLDivElement>(null);

  const prepEllipse = (node: HTMLDivElement) => {
    const parent = node.parentElement;
    const child = node.children[0] as HTMLElement;
    const txtToEllipse = (parent?.querySelector('.ellipseMe') || child) as HTMLElement;

    if (child !== null && txtToEllipse !== null && parent != null) {
      // (Re)-set text back to data-original-text if it exists.
      if (txtToEllipse.hasAttribute('data-original')) {
        txtToEllipse.textContent = txtToEllipse.getAttribute('data-original');
      }

      ellipse(
        // Use the smaller width.
        node.offsetWidth > parent.offsetWidth ? parent : node,
        child,
        txtToEllipse,
      );
    }
  };

  useEffect(() => {
    const node = measuredParent.current;
    if (!node) {
      return;
    }

    const resizeOberver = new ResizeObserver((entries) => {
      if (entries[0].contentBoxSize) {
        prepEllipse(node);
      }
    });

    resizeOberver.observe(node);
    return () => {
      resizeOberver.unobserve(node);
    };
  }, []);

  return (
    <div
      ref={measuredParent}
      className="whitespace-nowrap"
      style={{
        wordBreak: 'keep-all',
        overflowWrap: 'normal',
        ...(props.width && { width: props.width }),
      }}
    >
      <span>{props.children}</span>
    </div>
  );
};

export default MiddleTruncate;
