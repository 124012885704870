import { FilterInterface, Interweave } from 'interweave';
import { ActionPlaceholderData } from '../../../models/Form';
import { FCWithChildren } from '../../../types/FCWithChildren';
import { interpolateActionData } from '../../../utils/interpolation/ActionDataInterpolator';
import { FormBuilderPlaceholder } from '../../form-builder/FormBuilderTypes';
import { MatcherInterface } from 'interweave';
import { useEffect, useMemo, useState } from 'react';
import { useWarnOutboundLink } from '../../../hooks/useWarnOutboundLink';
import { SecureLink } from 'react-secure-link';
import { imageLoader } from '../../../utils/RichTextUtils';
import imagePlaceholderLoader from '../../../assets/images/skeleton-screen-animation.gif';

const dynamicDataMatcher = (placeholders: Record<string, ActionPlaceholderData> | FormBuilderPlaceholder[]): MatcherInterface => ({
  inverseName: 'noDynamicData',
  propName: 'dynamicData',
  match(string) {
    const result = string.match(/\${{[\S]+?}}/);

    if (!result) {
      return null;
    }

    return {
      index: result.index!,
      length: result[0].length,
      match: result[0],
      valid: true,
    };
  },
  createElement(children, props) {
    return <span key={props.key}>{interpolateActionData(children as string, placeholders)}</span>;
  },
  asTag() {
    return 'span';
  },
});

const renderCustomTag = (attributes: NamedNodeMap, children: React.ReactNode) => {
  return <span {...attributes}>{children}</span>;
};

const plainTextTransformer = (node: HTMLElement, children: React.ReactNode) => {
  const { tagName, attributes } = node;
  const lowerTagName = tagName.toLowerCase();
  const tagHandlers = {
    h1: () => renderCustomTag(attributes, children),
    h2: () => renderCustomTag(attributes, children),
    h3: () => renderCustomTag(attributes, children),
    h4: () => renderCustomTag(attributes, children),
    h5: () => renderCustomTag(attributes, children),
    h6: () => renderCustomTag(attributes, children),
    p: () => renderCustomTag(attributes, children),
    ol: () => (
      <span className="comma-list" {...attributes}>
        {children}
      </span>
    ),
    ul: () => (
      <span className="comma-list" {...attributes}>
        {children}
      </span>
    ),
    li: () => renderCustomTag(attributes, children),
    hr: () => renderCustomTag(attributes, children),
    em: () => renderCustomTag(attributes, children),
    strong: () => renderCustomTag(attributes, children),
    underline: () => renderCustomTag(attributes, children),
    code: () => renderCustomTag(attributes, children),
    blockquote: () => renderCustomTag(attributes, children),
    img: () => <span {...attributes}>{attributes.getNamedItem('src')?.value || ''}</span>,
  };

  if (lowerTagName in tagHandlers) {
    return tagHandlers[lowerTagName as keyof typeof tagHandlers]();
  }

  return undefined;
};

const HtmlPreview: FCWithChildren<{
  children: string;
  placeholders: Record<string, ActionPlaceholderData> | FormBuilderPlaceholder[];
  warnOutboundLinks?: boolean;
  plainText?: boolean;
}> = (props) => {
  const { placeholders, children, warnOutboundLinks, plainText } = props;
  const { onClick, modal } = useWarnOutboundLink();
  const [imageUrls, setImageUrls] = useState<Record<string, string>>({});

  const matcher = useMemo(() => dynamicDataMatcher(placeholders), [placeholders]);
  useEffect(() => {
    const loadImages = async () => {
      const promises: Promise<void>[] = [];
      const regex = /data-file-id="([^"]+)"/g;

      let match;
      const fileIds = [];
      while ((match = regex.exec(children)) !== null) {
        fileIds.push(match[1]);
      }

      fileIds.forEach((fileId) => {
        promises.push(
          imageLoader(undefined, fileId).then((url) => {
            setImageUrls((prev) => ({ ...prev, [fileId]: url }));
          }),
        );
      });

      await Promise.all(promises);
    };

    loadImages();
  }, [children]);

  const imageFilter: FilterInterface = {
    node(name, node) {
      if (name === 'img') {
        const src = imageUrls[node.getAttribute('data-file-id') as string] ?? imagePlaceholderLoader;
        node.setAttribute('src', src);
      }

      return node;
    },
  };

  return (
    <div className="placeholder-textbox-preview fr-element fr-view">
      <Interweave
        content={children}
        matchers={[matcher]}
        filters={[imageFilter]}
        transform={(node, children) => {
          if (node.tagName === 'A') {
            return (
              <SecureLink
                key={node.getAttribute('href')}
                href={node.getAttribute('href') || ''}
                onClick={warnOutboundLinks ? onClick : undefined}
                className="text-link-1 font-bold underline"
              >
                {children}
              </SecureLink>
            );
          }

          if (plainText) {
            return plainTextTransformer(node, children);
          }
          return undefined;
        }}
      />
      {warnOutboundLinks && modal}
    </div>
  );
};

export default HtmlPreview;
