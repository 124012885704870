import { Namespace } from 'i18next';
import { TFunction } from 'i18next';
import { Option } from '../components/Option';
import { Translations } from './Translation';
import { RiskLevel, RiskRating } from '../utils/RiskUtils';
import { ActionPlaceholderData } from './Form';

export enum RiskType {
  flagged,
  calculated,
}

export type Risk = {
  id: string;
  friendlyId: number;
  riskRating: RiskRating;
  clientFormSubtitle: string;
  placeholders: Record<string, ActionPlaceholderData>;
  sectionId: string;
  actionTranslations: Translations;
  statusUtc: string;
  lastModifiedUtc: string;
  documentNumber: number | null;
  templateModuleTranslations: Translations;
  type: RiskType;
  actionIndex: number;
  isValid?: boolean;
} & RiskRequest;

export type RiskCalculated = {
  score: number;
} & Risk;

export type RiskRequest = {
  clientFormId: string;
  actionId: string;
  title: string;
  description?: string;
  ownerId: string;
  status: RiskStatus;
  matrixVerticalId: string; // Probaility
  matrixHorizontalId: string; // Impact
  riskCategoryId: string;
};

export type RiskUpdateRequest = {
  reasonForChange: string;
} & RiskRequest;

export type RiskCategory = {
  id: string;
  name: string;
  matrix: RiskMatrix;
};

type RiskMatrix = {
  rowIds: string[];
  columnIds: string[];
  values: Record<string, Record<string, RiskRating>>;
  levels: Record<string, RiskLevel>;
  translations: Translations;
};

export enum RiskStatus {
  identified = 0,
  assessed = 1,
  prioritized = 2,
  mitigated = 3,
  monitored = 4,
  resolved = 5,
  accepted = 6,
  transferred = 7,
  avoided = 8,
  active = 9,
  inactive = 10,
}

export const RiskStatusKeys = {
  [RiskStatus.identified]: 'risk:risk-status.identified',
  [RiskStatus.assessed]: 'risk:risk-status.assessed',
  [RiskStatus.prioritized]: 'risk:risk-status.prioritized',
  [RiskStatus.mitigated]: 'risk:risk-status.mitigated',
  [RiskStatus.monitored]: 'risk:risk-status.monitored',
  [RiskStatus.resolved]: 'risk:risk-status.resolved',
  [RiskStatus.accepted]: 'risk:risk-status.accepted',
  [RiskStatus.transferred]: 'risk:risk-status.transferred',
  [RiskStatus.avoided]: 'risk:risk-status.avoided',
  [RiskStatus.active]: 'risk:risk-status.active',
  [RiskStatus.inactive]: 'risk:risk-status.inactive',
} as const;

export const RiskRatingKeys = {
  [RiskRating.CRITICAL_RISK]: 'risk:risk-rating.critical',
  [RiskRating.HIGH_RISK]: 'risk:risk-rating.high',
  [RiskRating.MEDIUM_RISK]: 'risk:risk-rating.medium',
  [RiskRating.LOW_RISK]: 'risk:risk-rating.low',
  [RiskRating.NO_RISK]: 'risk:risk-rating.low',
  [RiskRating.NO_CONTRIBUTION]: 'risk:risk-rating.low',
} as const;

export const riskRatingOptions = (t: TFunction<Namespace>): Option<string, string | RiskRating>[] => {
  return [
    {
      id: RiskRating.CRITICAL_RISK.toString(),
      text: t(RiskRatingKeys[RiskRating.CRITICAL_RISK]),
      value: RiskRating.CRITICAL_RISK,
    },
    {
      id: RiskRating.HIGH_RISK.toString(),
      text: t(RiskRatingKeys[RiskRating.HIGH_RISK]),
      value: RiskRating.HIGH_RISK,
    },
    {
      id: RiskRating.MEDIUM_RISK.toString(),
      text: t(RiskRatingKeys[RiskRating.MEDIUM_RISK]),
      value: RiskRating.MEDIUM_RISK,
    },
    {
      id: RiskRating.LOW_RISK.toString(),
      text: t(RiskRatingKeys[RiskRating.LOW_RISK]),
      value: RiskRating.LOW_RISK,
    },
  ];
};

export const riskStatusOptions = (t: TFunction<Namespace>): Option<string, string | RiskStatus>[] => {
  return [
    {
      id: RiskStatus.identified.toString(),
      text: t(RiskStatusKeys[RiskStatus.identified]),
      value: RiskStatus.identified,
    },
    {
      id: RiskStatus.assessed.toString(),
      text: t(RiskStatusKeys[RiskStatus.assessed]),
      value: RiskStatus.assessed,
    },
    {
      id: RiskStatus.prioritized.toString(),
      text: t(RiskStatusKeys[RiskStatus.prioritized]),
      value: RiskStatus.prioritized,
    },
    {
      id: RiskStatus.mitigated.toString(),
      text: t(RiskStatusKeys[RiskStatus.mitigated]),
      value: RiskStatus.mitigated,
    },
    {
      id: RiskStatus.monitored.toString(),
      text: t(RiskStatusKeys[RiskStatus.monitored]),
      value: RiskStatus.monitored,
    },
    {
      id: RiskStatus.resolved.toString(),
      text: t(RiskStatusKeys[RiskStatus.resolved]),
      value: RiskStatus.resolved,
    },
    {
      id: RiskStatus.accepted.toString(),
      text: t(RiskStatusKeys[RiskStatus.accepted]),
      value: RiskStatus.accepted,
    },
    {
      id: RiskStatus.transferred.toString(),
      text: t(RiskStatusKeys[RiskStatus.transferred]),
      value: RiskStatus.transferred,
    },
    {
      id: RiskStatus.avoided.toString(),
      text: t(RiskStatusKeys[RiskStatus.avoided]),
      value: RiskStatus.avoided,
    },
    {
      id: RiskStatus.active.toString(),
      text: t(RiskStatusKeys[RiskStatus.active]),
      value: RiskStatus.active,
    },
    {
      id: RiskStatus.inactive.toString(),
      text: t(RiskStatusKeys[RiskStatus.inactive]),
      value: RiskStatus.inactive,
    },
  ];
};
