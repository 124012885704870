import { createElement, FC, useMemo } from 'react';
import { useFormRendererInfo } from '../../../contexts/FormRendererContext';
import { useFormRendererDesign } from '../../../contexts/FormRendererDesignContext';
import { FormRendererMode } from '../../../contexts/FormRendererDesignContextTypes';
import { FormSectionRendererInfoContext } from '../../../contexts/FormSectionRendererContext';
import { ClientFormSectionStatus } from '../../../models/ClientFormSectionStatus';
import { FormAction, FormSection } from '../../../models/Form';
import FormActionRendererV2 from './FormActionRendererV2';

type FormStepRendererV2Props = {
  step: FormSection;
  stepStatus: ClientFormSectionStatus;
  actionOrder: string[];
  actionFilter?: (action: FormAction) => boolean;
  mode: FormRendererMode;
};

const FormSectionRendererV2: FC<FormStepRendererV2Props> = (props) => {
  const { step, actionFilter, stepStatus, actionOrder, mode } = props;
  const { featureToggles, visibleActions, actionLookup, requiredActions } = useFormRendererInfo();
  const { sectionWrapper, sectionTitleProvider } = useFormRendererDesign();

  const actionsToRender = useMemo(() => {
    let actions = actionOrder.filter((id) => visibleActions[id]).map((id) => actionLookup[id]);

    if (mode === FormRendererMode.PreviewView) {
      actions = actions.filter((x) => !x.previewHidden);
    }

    return actionFilter ? actions.filter(actionFilter) : actions;
  }, [actionFilter, actionLookup, actionOrder, mode, visibleActions]);

  return (
    <FormSectionRendererInfoContext.Provider value={{ mode, step }}>
      {createElement(
        sectionWrapper,
        { step },
        <>
          {!featureToggles?.disableSectionHeading && actionsToRender.length > 0 && createElement(sectionTitleProvider, { step, stepStatus, mode })}
          <div className="px-4">
            {actionsToRender.map((action, i) => (
              <FormActionRendererV2
                key={action.id}
                action={action}
                step={step}
                isRequired={requiredActions[action.id]}
                isLast={i === actionsToRender.length - 1}
              />
            ))}
          </div>
        </>,
      )}
    </FormSectionRendererInfoContext.Provider>
  );
};

export default FormSectionRendererV2;
