import { FC, useCallback } from 'react';
import { FileUploadType } from '../shared/file-upload/FileuploadType';
import { PlaceholderFormat } from '../../models/Form';
import { FileInfo } from '../shared/file-upload/FileInfo';
import FilePreview from './renderer/FilePreview';
import { useWarnOutboundLink } from '../../hooks/useWarnOutboundLink';
import { SecureLink } from 'react-secure-link';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FilePlaceholderLink: FC<{ value: any; dataformat?: PlaceholderFormat }> = ({ value, dataformat }) => {
  const files = Array.isArray(value) ? value : [value];

  const { modal: externalLinkModal, onClick: externalLinkClick } = useWarnOutboundLink();

  const fileLinkFor = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (file: any) => {
      let fileInfo: FileInfo = file;
      if (file.type === FileUploadType.File || file.type === FileUploadType.Link) {
        fileInfo = file.fileInfo;
      }
      if (fileInfo.type === FileUploadType.Link) {
        return (
          <SecureLink key={fileInfo.id} href={fileInfo.url} className="text-link-1 font-medium underline" onClick={externalLinkClick}>
            {fileInfo.name}
          </SecureLink>
        );
      }
      return (
        <FilePreview fileInfo={fileInfo}>
          <span key={fileInfo.id} className="text-link-1 cursor-pointer font-medium hover:underline">
            {fileInfo.name}
          </span>
        </FilePreview>
      );
    },
    [externalLinkClick],
  );

  return (
    <>
      {dataformat === 'bullet' ? (
        <span>
          <ul className="ml-8 list-outside list-disc">
            {files.map((file, i) => (
              <li key={'file-placeholder_' + i}> {fileLinkFor(file)} </li>
            ))}
          </ul>
        </span>
      ) : (
        <>
          {files
            .map((file) => fileLinkFor(file))
            .reduce((result, item) => (
              <>
                {result}, {item}
              </>
            ))}
        </>
      )}
      {externalLinkModal}
    </>
  );
};

export default FilePlaceholderLink;
