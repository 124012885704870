import { Namespace, TFunction } from 'i18next';
import EvaluationEngine from '../components/form/EvaluationEngine';
import { FormAction, FormConfig } from '../models/Form';
import { FormType } from '../models/FormTypes';

type RiskScore = {
  score: number;
  questions: number;
};

export enum RiskRating {
  NO_CONTRIBUTION = -1,
  NO_RISK = 0,
  LOW_RISK = 1,
  MEDIUM_RISK = 2,
  HIGH_RISK = 3,
  CRITICAL_RISK = 4,
}

export enum RiskLevel {
  VERY_LOW = 1,
  LOW = 2,
  MEDIUM = 3,
  HIGH = 4,
  VERY_HIGH = 5,
}

class RiskUtils {
  /**
   * Rules:
   * 1. If risk tickbox not enabled (or if not visible), don't count towards risk average
   * 2. If no conditions apply/is set up, high risk
   * 3. Take max risk if more than one applies
   */
  public static calculateRiskForAction(action: FormAction, form: FormConfig) {
    // Not visible or not enabled - doesn't contribute to risk score
    if (!action.riskEnabled || !action.riskConditions || !action.visible) {
      return { score: RiskRating.NO_CONTRIBUTION, matchedCondition: null };
    }

    if (action.riskConditions.length === 0) {
      return {
        score: action.riskNoMatch === 'Infinity' ? Infinity : parseInt(action.riskNoMatch || '-1', 10) || RiskRating.NO_CONTRIBUTION,
        matchedCondition: null,
      };
    }
    //"6590ec00-6f48-4894-af3e-38f011dc0aa7$.id = 'yes' -> 1,-1
    const calcScores = action.riskConditions
      .map((condition) => ({ condition: condition, score: EvaluationEngine(condition, form) as string }))
      .map((x) => ({
        ...x,
        score:
          x.score === 'Infinity'
            ? Infinity
            : Math.max(RiskRating.NO_RISK, Math.min(RiskRating.HIGH_RISK, parseInt(x.score, 10))) || RiskRating.NO_CONTRIBUTION,
      }));

    const maxScore = Math.max(...calcScores.map((x) => x.score));
    const matchedCondition = calcScores.find((x) => x.score === maxScore)?.condition;
    const scores = calcScores.map((x) => x.score).filter((x) => x !== RiskRating.NO_CONTRIBUTION);

    // If action doesn't contribute anything, but we set `riskNoMatch`, return that as the risk
    const actionScore = scores.length === 0 ? RiskRating.NO_CONTRIBUTION : Math.max(...scores) || RiskRating.NO_CONTRIBUTION;
    if (scores.length === 0 || actionScore === RiskRating.NO_CONTRIBUTION) {
      return { score: (action.riskNoMatch === 'Infinity' ? Infinity : parseInt(action.riskNoMatch || '-1', 10)) || actionScore, matchedCondition };
    }

    return { score: actionScore, matchedCondition };
  }

  public static calculateRiskForForm(form: FormConfig): RiskScore {
    const scores = form.sections
      .map((section) => section.actions)
      .flat()
      .filter((action) => action.riskEnabled)
      .map((action) => this.calculateRiskForAction(action, form))
      .filter((x) => x.score !== RiskRating.NO_CONTRIBUTION);

    // No scores that count are on the form
    if (scores.length === 0) {
      return { score: RiskRating.NO_RISK, questions: 0 };
    }

    // Calculate form average
    const sum = scores.map((x) => x.score).reduce((prev, curr) => prev + curr, 0);
    return { score: sum / scores.length, questions: scores.length };
  }

  public static textColorFor(riskLevel: number): string {
    if (riskLevel === RiskRating.NO_CONTRIBUTION || riskLevel == RiskRating.NO_RISK || !riskLevel) {
      return '';
    }

    return riskLevel >= 2.33 ? 'text-semantic-2' : riskLevel >= 1.66 ? 'text-semantic-3' : 'text-semantic-1';
  }

  public static bgColorFor(riskLevel: number): string {
    if (riskLevel === RiskRating.NO_CONTRIBUTION || riskLevel == RiskRating.NO_RISK || !riskLevel) {
      return '';
    }

    return riskLevel >= 2.33 ? 'bg-semantic-2' : riskLevel >= 1.66 ? 'bg-semantic-3' : 'bg-semantic-1';
  }

  public static bgColorForRating(rating: RiskRating): string {
    switch (rating) {
      case RiskRating.CRITICAL_RISK: {
        return 'bg-semantic-alert';
      }
      case RiskRating.HIGH_RISK: {
        return 'bg-semantic-alert bg-opacity-10';
      }
      case RiskRating.MEDIUM_RISK: {
        return 'bg-semantic-3 bg-opacity-10';
      }
      case RiskRating.LOW_RISK: {
        return 'bg-semantic-light-2 bg-opacity-10';
      }

      default:
        return '';
    }
  }

  public static textColorForRating(rating: RiskRating): string {
    switch (rating) {
      case RiskRating.CRITICAL_RISK:
      case RiskRating.HIGH_RISK: {
        return 'text-semantic-alert';
      }
      case RiskRating.MEDIUM_RISK: {
        return 'text-semantic-3';
      }
      case RiskRating.LOW_RISK: {
        return 'text-semantic-light-2';
      }
      default:
        return '';
    }
  }

  public static textColorForRiskLevel(level: RiskLevel): string {
    switch (level) {
      case RiskLevel.VERY_HIGH:
      case RiskLevel.HIGH: {
        return 'text-semantic-alert';
      }
      case RiskLevel.MEDIUM: {
        return 'text-semantic-3';
      }
      case RiskLevel.LOW: {
        return 'text-accent-3';
      }
      case RiskLevel.VERY_LOW: {
        return 'text-semantic-light-2';
      }
      default:
        return '';
    }
  }

  /**
   * @param t MUST include 'common' namespace
   */
  public static textFor(
    t: TFunction<Namespace>,
    score: { totalRiskScore: number; type?: (typeof FormType)[keyof typeof FormType]; isSystemTemplateForm?: boolean } | number,
  ): string {
    const riskLevel = typeof score === 'number' ? score : score.totalRiskScore;
    if (typeof score !== 'number') {
      const { type, isSystemTemplateForm } = score;
      if (type === FormType.Document && isSystemTemplateForm) {
        return t('common:forms.no-risk-calculated');
      }
    }

    if (riskLevel === RiskRating.NO_CONTRIBUTION || riskLevel == RiskRating.NO_RISK || !riskLevel) {
      return t('common:risk-level.no-risk');
    }

    return riskLevel === RiskRating.CRITICAL_RISK || riskLevel > 3
      ? t('risk:risk-rating.critical')
      : riskLevel >= 2.33
        ? t('risk:risk-rating.high')
        : riskLevel >= 1.66
          ? t('risk:risk-rating.medium')
          : t('risk:risk-rating.low');
  }
}

export default RiskUtils;
