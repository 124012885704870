import { FC, memo, ReactNode, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ActionPlaceholderData, PlaceholderFormat } from '../../models/Form';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import { interpolateActionData } from '../../utils/interpolation/ActionDataInterpolator';
import { FormRendererType, useFormRendererInfo } from '../../contexts/FormRendererContext';

type Props = Omit<ActionPlaceholderData, 'answers'> & {
  answers: string[];
  dataFormat: PlaceholderFormat;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wrapper?: FC<{ value: any; dataformat?: PlaceholderFormat }> | keyof JSX.IntrinsicElements;
  otherPlaceholders: Record<string, ActionPlaceholderData>;
};

const DefaultPlaceholderFormat: FC<Props> = (props) => {
  const { answers, dataFormat, wrapper: Wrapper = 'span', clientFormIds, actionIds, otherPlaceholders } = props;
  const { clientId, formId: urlClientFormId } = useParams<{ formId: string; clientId: string }>();
  const { type } = useFormRendererInfo();

  const getClientFormId = useCallback(
    (index: number) => {
      if (clientFormIds.length === 1 && clientFormIds[0] === urlClientFormId) {
        return urlClientFormId;
      }

      return clientFormIds[index];
    },
    [clientFormIds, urlClientFormId],
  );

  const LinkComponentFor = useCallback(
    (index: number, children: ReactNode) => {
      const clientFormId = getClientFormId(index);
      if (!clientFormId) {
        return <span>{children}</span>;
      }
      const highlight = actionIds[index] ? '?highlight=' + clientFormId + ',,' + actionIds[index] : '';
      const to = `/clients/${clientId}/forms/${clientFormId}${highlight}`;
      if (type === FormRendererType.Platform) {
        return (
          <Link to={to} target="_blank" className="inline hover:underline">
            {children}
          </Link>
        );
      } else {
        return <>{children}</>;
      }
    },
    [actionIds, clientId, getClientFormId, type],
  );

  if (answers.length === 0) {
    return null;
  }

  if (answers.length === 1) {
    return LinkComponentFor(
      0,
      <Wrapper value={answers[0]} dataformat={dataFormat} key="placeholder_wrapper1">
        {interpolateActionData(answers[0], otherPlaceholders)}
      </Wrapper>,
    );
  }

  if (dataFormat === 'bullet') {
    return (
      <span>
        <ul className="ml-8 list-outside list-disc">
          {answers.map((answer, i) => {
            return (
              <li key={'placeholder_' + i}>
                {LinkComponentFor(
                  i,
                  <Wrapper value={answer} dataformat={dataFormat} key={'placeholder_wrapper' + i}>
                    {answer}
                  </Wrapper>,
                )}
              </li>
            );
          })}
        </ul>
      </span>
    );
  }
  return (
    <>
      {answers
        .map((answer, i) => {
          return LinkComponentFor(
            i,
            <Wrapper value={answer} dataformat={dataFormat} key={'placeholder_' + i}>
              {answer}
            </Wrapper>,
          );
        })
        .reduce((result, item) => (
          <>
            {result}, {item}
          </>
        ))}
    </>
  );
};

export default memo(DefaultPlaceholderFormat);
