import { Translations } from './Translation';

export type PickListItem = {
  id: string;
  isCustom: boolean;
  isDisabled: boolean;
  translations: Translations<'name' | 'description'>;
};

export type PickListInfo = {
  id: string;
  clientId: string | null;
  isSystem: boolean;
  translations: Translations<'name' | 'description'>;
  itemsCount: number;
  modifiedUtc: string;
  archivedUtc: string;
};

export type PickList = {
  items: PickListItem[];
} & PickListInfo;

export type PickListDto = {
  isSystem: boolean;
  clientId?: string;
  translations: Translations<'name' | 'description'>;
};

export type GetPicklistsRequest = {
  isSystem?: boolean;
  includeArchived?: boolean;
  searchTerm?: string;
  pageNumber?: number;
  pageSize?: number;
  searchLanguage?: string;
  clientId?: string;
  excludeClientless?: boolean;
};

export const CustomSourceKeyAssociciatedClients = '\0ASSOCIATED_CLIENTS\0';
export const AssetTemplateSourceKey = 'asset_template_';
