import { MouseEvent as ReactMouseEvent, useCallback, useMemo, useRef, useState } from 'react';
import { ModalContext } from '../contexts/ModalContext';
import StandardModal from '../components/shared/modal/variants/StandardModal';
import { Trans, useTranslation } from 'react-i18next';
import MiddleTruncate from '../components/shared/MiddleTruncate';

export const useWarnOutboundLink = () => {
  const [blockedUrl, setBlockedUrl] = useState('');
  const aTag = useRef<HTMLAnchorElement>();
  const { t } = useTranslation('common');

  const shouldBlock = useCallback((href: string) => {
    const target = new URL(href);
    return window.location.protocol !== target.protocol || window.location.host !== target.host;
  }, []);

  const onClick = useCallback(
    (e: ReactMouseEvent<HTMLAnchorElement> | MouseEvent) => {
      const anchor = (e.target as Element).closest('a');

      if (!anchor) {
        return;
      }

      // allow through if user confirmed it -> aTag will be set if we're showing the modal
      // doing this so that document.createElement a tags still work
      if (aTag.current) {
        setBlockedUrl('');
        aTag.current = undefined;
      } else if (shouldBlock(anchor.href)) {
        e.preventDefault();
        e.stopPropagation();
        setBlockedUrl(anchor.href);
        aTag.current = anchor;
      }
    },
    [shouldBlock],
  );

  const modalCancel = useCallback(() => {
    setBlockedUrl('');
    aTag.current = undefined;
  }, []);

  const modal = useMemo(() => {
    return (
      <ModalContext.Provider value={{ open: !!blockedUrl, onClose: modalCancel, modalWidth: 'w-2/5' }}>
        <StandardModal
          title={t('external-link.title')}
          cancelButtonTitle={t('external-link.cancel')}
          confirmButtonTitle={t('external-link.confirm')}
          onCancelClick={modalCancel}
          onConfirmClick={() => aTag.current?.click()}
        >
          <Trans t={t} i18nKey="external-link.body-1" components={{ p: <p className="my-2" /> }} />
          <div className="text-link-1 font-bold">
            <MiddleTruncate>{blockedUrl}</MiddleTruncate>
          </div>
          <Trans t={t} i18nKey="external-link.body-2" components={{ p: <p className="my-2" /> }} />
        </StandardModal>
      </ModalContext.Provider>
    );
  }, [blockedUrl, modalCancel, t]);

  return { onClick, modal };
};
