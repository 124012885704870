import { ApiResponse } from '../models/ApiResponse';
import { PickListItem, PickListInfo, PickList, PickListDto, GetPicklistsRequest } from '../models/Picklist';
import { Translations } from '../models/Translation';
import BaseService from './BaseService';

export default class PickListService extends BaseService {
  public static create(pickList: PickListDto): Promise<ApiResponse<PickListInfo>> {
    return this.post('/v1/picklists', pickList);
  }

  public static update(picklist: PickListInfo): Promise<ApiResponse<PickListItem>> {
    return this.put(`/v1/picklists/${picklist.id}`, picklist);
  }

  public static getAll(request: GetPicklistsRequest = {}): Promise<ApiResponse<PickListInfo[]>> {
    return this.get('/v1/picklists', { params: request });
  }

  public static getPickList(code: string, includeDisabled?: boolean, publicFormId?: string): Promise<ApiResponse<PickList>> {
    if (code.indexOf('\0') !== -1) {
      throw new Error('You should not be getting the picklist of a custom entry!');
    }

    return this.get(`/v1/picklists/${code}`, { params: { includeDisabled, publicFormId } });
  }

  public static archive(code: string): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/picklists/${code}/archive`);
  }

  public static unarchive(code: string): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/picklists/${code}/unarchive`);
  }

  public static updateSortOrder(code: string, ids: string[]): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/picklists/${code}/sort`, ids);
  }

  public static addItem(pickListId: string, translations: Translations): Promise<ApiResponse<PickListItem>> {
    return this.post('/v1/picklists/items', { translations, pickListId });
  }

  public static addCustomItem(clientId: string, pickListId: string, translations?: Translations): Promise<ApiResponse<PickListItem>> {
    return this.post('/v1/picklists/items', { pickListId, clientId, translations });
  }

  public static updateItem(itemId: string, item: PickListItem): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/picklists/items/${itemId}`, item);
  }

  public static deleteItem(itemId: string): Promise<ApiResponse<boolean>> {
    return this.delete(`/v1/picklists/items/${itemId}`);
  }

  public static disableItem(itemId: string): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/picklists/items/${itemId}/disable`);
  }

  public static enable(itemId: string): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/picklists/items/${itemId}/enable`);
  }

  public static reinstatePicklistItem(itemId: string): Promise<ApiResponse<boolean>> {
    return this.post(`/v1/picklists/items/${itemId}/undelete`);
  }
}
