import { FC } from 'react';
import { isEmailValid } from '../../utils/EmailUtils';

const MailtoPlaceholderLink: FC<{ value: string }> = ({ value }) => {
  if (!isEmailValid(value)) {
    return <>{value}</>;
  }
  return (
    <a className="text-link-1 cursor-pointer font-medium hover:underline" href={`mailto:${value}`} target="_blank" rel="noreferrer">
      {value}
    </a>
  );
};

export default MailtoPlaceholderLink;
