import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../shared/Tooltip';

const PlaceholderMissing: FC = () => {
  const { t } = useTranslation('common');
  return (
    <Tooltip text={t('placeholder-missing.description')}>
      {(tooltip) => (
        <span {...tooltip} className="text-semantic-2">
          {'{' + t('placeholder-missing.text') + ' ⚠}'}
        </span>
      )}
    </Tooltip>
  );
};

export default memo(PlaceholderMissing);
