import { FC, ReactElement } from 'react';
import { dataAttributeProps } from '../../../utils/ComponentUtils';

const IconBadge: FC<{ icon: ReactElement; text?: string | number; onClick?: () => void }> = (props) => {
  const { icon, text, onClick } = props;
  return (
    <div className={`bg-background-1 inline-flex cursor-pointer flex-row items-center rounded-[5px] px-3 py-1`} onClick={onClick}>
      <div {...dataAttributeProps(props)} className="flex items-center gap-2">
        {icon}
        {text !== undefined && <span className="text-dpm-14 w-full text-center font-medium">{text}</span>}
      </div>
    </div>
  );
};

export default IconBadge;
