import { ReactNode } from 'react';
import { ClientFormSectionStatus } from '../models/ClientFormSectionStatus';
import { FormSection } from '../models/Form';

export type FormStepWrapperProps = {
  step: FormSection;
};

export type FormActionWrapperProps = {
  isLast: boolean;
  children?: ReactNode | undefined;
};

export type FormStepTitleProviderProps = {
  step: FormSection;
  mode: FormRendererMode;
  stepStatus: ClientFormSectionStatus;
};

export enum FormRendererMode {
  EditView,
  OverviewView,
  PreviewView,
}
