import { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useFormAction } from '../../../contexts/FormActionContext';
import { useFormRendererInfo } from '../../../contexts/FormRendererContext';
import { FormActionWrapperProps, FormRendererMode } from '../../../contexts/FormRendererDesignContextTypes';
import { useFormSectionRendererInfo } from '../../../contexts/FormSectionRendererContext';
import { EventSystem } from '../../../events/EventSystem';
import CommentEvent from '../../../events/QuestionCommentEvent';
import { currentActivityCommentsStats } from '../../../recoil/atoms/Forms';
import { FCWithChildren } from '../../../types/FCWithChildren';
import { nextTick } from '../../../utils/ReactUtils';
import CheckIcon from '../../shared/icon/CheckIcon';
import Loader from '../../shared/Loader';
import ContextMenu from '../../shared/ContextMenu';
import CommentIcon from '../../shared/icon/CommentIcon';
import FlagIcon from '../../shared/icon/FlagIcon';
import CirclePlusIcon from '../../shared/icon/CirclePlusIcon';
import { useTranslation } from 'react-i18next';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormType } from '../../../models/FormTypes';
import FormUtils from '../../../utils/FormUtils';
import { DocumentResponse } from '../../../models/Document';
import usePermissions from '../../../hooks/permissions/usePermissions';
import { Roles } from '../../../models/Role';

const FormActionWrapperV2: FCWithChildren<FormActionWrapperProps> = (props) => {
  const { children } = props;

  const { isDisplayedInline, clientFormId, toHighlight, featureToggles, answersBusySaving, rootContext, placeholders } = useFormRendererInfo();
  const { mode } = useFormSectionRendererInfo();
  const { currentAction, actionValid, currentSection } = useFormAction();
  const { t } = useTranslation(['form']);
  const commentStats = useRecoilValue(currentActivityCommentsStats);

  const [highlighted, setHighlighted] = useState(false);
  const selfRef = useRef<HTMLDivElement>(null);

  const commentCount = useMemo(() => commentStats.actionCommentsCounts[currentAction.id], [commentStats.actionCommentsCounts, currentAction.id]);

  const hasPermission = usePermissions();

  useEffect(() => {
    if (!toHighlight) {
      setHighlighted(false);
      return;
    }
  }, [toHighlight]);

  useEffect(() => {
    if (toHighlight) {
      const isHighlighted =
        toHighlight.formId === clientFormId && toHighlight.sectionId === currentSection.id && currentAction.id === toHighlight.sourceId;
      setHighlighted(isHighlighted);
      if (isHighlighted && commentCount > 0 && !toHighlight.isRisk) {
        EventSystem.fireEvent('question-comment-open', {
          sourceId: currentAction.id,
          sectionId: currentSection.id,
        });
      }
    }
  }, [clientFormId, commentCount, currentAction.id, currentSection.id, toHighlight]);

  useEffect(() => {
    if (highlighted) {
      nextTick(() => selfRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'end' }));
    }
  }, [highlighted]);

  const shouldDisplayActionsButton = useMemo(() => !isDisplayedInline, [isDisplayedInline]);

  useEffect(() => {
    const handleCommentOpen = (event: CommentEvent) => {
      setHighlighted(currentAction.id === event.sourceId);
    };

    const handleCommentCancel = () => {
      setHighlighted(false);
    };

    EventSystem.listen('question-comment-open', handleCommentOpen);
    EventSystem.listen('comment-cancel', handleCommentCancel);
    EventSystem.listen('question-comment-new', handleCommentOpen);

    return () => {
      EventSystem.stopListening('question-comment-open', handleCommentOpen);
      EventSystem.stopListening('comment-cancel', handleCommentCancel);
      EventSystem.stopListening('question-comment-new', handleCommentOpen);
    };
  }, [currentAction.id]);

  return (
    <div
      data-cy="action-wrapper"
      className={`mb-2 transition-colors ${highlighted ? 'bg-accent-light-mid' : ''} group flex rounded-lg ${
        shouldDisplayActionsButton &&
        !currentAction.noninteractive &&
        !featureToggles?.disableQuestionCommentIcon &&
        !rootContext?.clientForm?.originId &&
        !highlighted
          ? 'hover:bg-gray-100 [&:has(:focus)]:bg-gray-100'
          : ''
      }`}
      ref={selfRef}
    >
      <div className="flex flex-grow rounded p-4">
        {mode === FormRendererMode.EditView && (
          <div className="w-7 flex-shrink-0">
            {!currentAction.noninteractive &&
              (answersBusySaving[currentAction.id] ? (
                <Loader size={6} centered={false} />
              ) : (
                <CheckIcon className={`bg-opacity-100 text-white ${actionValid ? 'bg-semantic-light-2' : 'bg-gray-4'} h-5 w-5 rounded-full p-1`} />
              ))}
          </div>
        )}
        <div className="w-full flex-grow bg-opacity-100">{children}</div>
      </div>
      {shouldDisplayActionsButton && (
        <div className="flex w-14 items-center justify-end px-2">
          {!currentAction.noninteractive &&
            !featureToggles?.disableQuestionCommentIcon &&
            /* Start a new thread for the Question */
            !rootContext?.clientForm?.originId && (
              <ContextMenu
                items={[
                  {
                    title: t('action-menu.comment'),
                    icon: <CommentIcon className="h-4 w-4" />,
                    onClick: () => {
                      EventSystem.fireEvent('question-comment-new', {
                        sourceId: currentAction.id,
                        sectionId: currentSection.id,
                      });
                    },
                  },
                  {
                    title: t('action-menu.risk'),
                    icon: <FlagIcon className="h-5 w-5" />,
                    onClick: () =>
                      EventSystem.fireEvent('question-risk-new', {
                        action: currentAction,
                        clientFormId: rootContext?.clientFormId || '',
                        clientFormTitle:
                          rootContext?.clientForm?.type === FormType.Document
                            ? FormUtils.getDocumentTitle(rootContext?.clientForm as DocumentResponse)
                            : rootContext?.clientForm?.subtitle || LanguageUtils.getTranslation('title', rootContext?.config.translations || {}),
                        placeholders: placeholders,
                      }),
                    hide: !hasPermission([Roles.Employee, Roles.ExternalContributor]) && hasPermission(Roles.ExternalAuditor),
                  },
                ]}
                type="icon"
              >
                {(ref, isOpen) => (
                  <div ref={ref}>
                    <CirclePlusIcon
                      className={`${isOpen ? 'visible' : 'invisible'} text-accent-light-mid h-8 w-8 flex-shrink-0 cursor-pointer group-hover:visible group-[&:has(:focus)]:visible`}
                    />
                  </div>
                )}
              </ContextMenu>
            )}
        </div>
      )}
    </div>
  );
};

export default FormActionWrapperV2;
