import i18n from '../i18n';
import { SupportedLanguage } from '../types/Languages';
import { Translations } from '../models/Translation';
import { DocumentListItem, DocumentResponse } from '../models/Document';
import LanguageUtils from './LanguageUtils';
import StringUtils from './StringUtils';
import { ActionPlaceholderData } from '../models/Form';
import { toRecord } from './ListUtils';
import { FormBuilderPlaceholder } from '../components/form-builder/FormBuilderTypes';

type FormLite = {
  subtitle?: string;
  form: {
    translations: Translations;
  };
};

export default class FormUtils {
  public static getFormTitle(formLite: FormLite, lang: SupportedLanguage | null = null): string {
    const language: SupportedLanguage = lang || i18n.language;
    const translations = formLite.form?.translations ? formLite.form.translations[language] || {} : {};
    return formLite.subtitle || translations.title || '';
  }

  public static getDocumentTitle(document: DocumentListItem | DocumentResponse) {
    return `${StringUtils.makePrefixWithNumber(document.prefix, document.documentNumber, document.templateModuleTranslations)}-${
      document.subtitle || LanguageUtils.getTranslation('title', 'translations' in document ? document.translations : document.form.translations)
    }`;
  }

  public static getFormTemplateTitle(form: FormLite, lang: SupportedLanguage | null = null): string {
    const language: SupportedLanguage = lang || i18n.language;
    const translations = form.form?.translations ? form.form.translations[language] || {} : {};
    return translations.title || '';
  }

  public static formHostName = (formClient: { clientId: string; clientName: string }, clientId: string, format?: string): string => {
    if (formClient.clientId !== clientId) {
      return format ? format.replace('{client}', formClient.clientName) : `${formClient.clientName}`;
    }
    return '';
  };

  public static formPlaceholdersToActionPlaceholderData = (placeholders?: FormBuilderPlaceholder[]) => {
    return placeholders
      ? toRecord(
          placeholders.map(
            (x) =>
              ({
                placeholder: x.placeholder,
                actionType: 'DateAction', // value doesn't matter, but need to provide it to satisfy type
                translations: x.translations,
                friendlyName: x.friendlyName,
                dataFormat: x.dataFormat,
                answers: [],
                clientFormIds: [],
                actionIds: [],
              }) as ActionPlaceholderData & { placeholder: string },
          ),
          'placeholder',
        )
      : {};
  };
}
