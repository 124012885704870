import { useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import { useFormRendererInfo } from '../../contexts/FormRendererContext';
import { FCWithChildren } from '../../types/FCWithChildren';
import { systemDefaultLanguageCode } from '../../types/Languages';
import CurrencyList from 'currency-list';
import Tooltip from '../shared/Tooltip';

const CurrencyPlaceholderWrapper: FCWithChildren<{ value: string }> = ({ value }) => {
  const [currencyCode, amount] = value.split(' ', 2);
  const { language = systemDefaultLanguageCode } = useFormRendererInfo();

  const currency = useMemo(() => CurrencyList.get(currencyCode, language), [currencyCode, language]);
  return (
    <span>
      <Tooltip text={currency.name}>
        {(tooltip) => (
          <span {...tooltip} className="font-semibold">
            {currency.symbol}
          </span>
        )}
      </Tooltip>{' '}
      <NumericFormat value={amount} valueIsNumericString thousandSeparator=" " displayType="text" />
    </span>
  );
};

export default CurrencyPlaceholderWrapper;
