import { SupportedLanguage } from '../types/Languages';
import { FormType } from './FormTypes';

export enum Placeholders {
  USER = '{User}',
  // User that was responsible
  PRIMARY_USER = '{PrimaryUser}',
  // User that was impacted by the action
  SECONDARY_USER = '{SecondaryUser}',
  FORM = '{Form}',
  FORM_ROLE = '{FormRole}',
  SUB_TITLE = '{SubTitle}',
  START_DATE = '{StartDate}',
  DUE_DATE = '{DueDate}',
  EFFECTIVE_DATE = '{EffectiveDate}',
  DUE_ON_DATE = '{DueOnDate}',
  MODULE = '{Module}',
  COMMENT = '{Comment}',
  FORM_SECTION = '{FormSection}',
  FORM_COUNT = '{FormCount}',
  IMPORT_JOB = '{ImportJob}',
  EXPORT_JOB = '{ExportJob}',
  FILE_NAME = '{FileName}',
  STATUS = '{Status}',
  REVIEW_PERIOD = '{ReviewPeriod}',
  REVIEW_PERIOD_TYPE = '{ReviewPeriodType}',
  TYPE = '{Type}',
  RISK_FLAGGED = '{RiskFlagged}',
  RISK_UPDATED_MULTIPLE = '{RiskUpdatedMultiple}',
  RISK_UPDATED_SINGULAR = '{RiskUpdatedSingular}',
  RISK_FIELD = '{RiskUpdatedSingular.change.fieldName}',
  RISK_PREVIOUS_VALUE = '{RiskUpdatedSingular.change.before}',
  RISK_CURRENT_VALUE = '{RiskUpdatedSingular.change.after}',
  RISK_REASON_FOR_CHANGE_MULTIPLE = '{RiskUpdatedMultiple.ReasonForChange}',
  RISK_REASON_FOR_CHANGE_SINGULAR = '{RiskUpdatedSingular.ReasonForChange}',
  RISK_REASON_FOR_CHANGE_OWNER = '{RiskAssigned.ReasonForChange}',
  RISK_OWNER_BEFORE = '{RiskAssigned.ownerBefore}',
  RISK_OWNER_AFTER = '{RiskAssigned.ownerAfter}',
}

export type TranslatablePlaceholderValue<T> = T | Record<SupportedLanguage, T>;

export interface UserPlaceholder {
  id: string;
  userName: string;
}

export interface FormPlaceholder {
  title: TranslatablePlaceholderValue<string>;
  moduleId: string;
  formId: string;
  clientId: string;
}

export interface ModulePlaceholder {
  name: TranslatablePlaceholderValue<string>;
  description: string;
}

export interface CommentPlaceholder {
  formId: string;
  clientId: string;
  commentId: string;
  text: string;
}

export type ImportJobPlaceholder = {
  id?: string;
  importId?: string;
  type: (typeof FormType)[keyof typeof FormType];
  clientId: string;
  formTitle: TranslatablePlaceholderValue<string>;
  templateId: string;
  moduleId: string;
};

export type ExportJobPlaceholder = {
  clientId: string;
};
