import { DocRenderer } from '@cyntler/react-doc-viewer';

const ImageProxyRenderer: DocRenderer = (props) => {
  const {
    mainState: { currentDocument },
    children,
  } = props;

  if (!currentDocument) return null;

  return (
    <div
      id="image-renderer"
      {...props}
      style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', backgroundColor: '#fff' }}
    >
      {children || <img id="image-img" style={{ maxWidth: '95%', maxHeight: '95%' }} src={currentDocument.fileData as string} />}
    </div>
  );
};

ImageProxyRenderer.fileTypes = [];
ImageProxyRenderer.weight = 0;

const SvgRenderer: DocRenderer = (props) => <ImageProxyRenderer {...props} />;

SvgRenderer.fileTypes = ['svg', 'image/svg+xml'];
SvgRenderer.weight = 0;

export default SvgRenderer;
