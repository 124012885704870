import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionPlaceholderData } from '../../models/Form';
import Tooltip from '../shared/Tooltip';

const PlaceholderFiendlyName: FC<{ placeholderData: ActionPlaceholderData }> = ({ placeholderData }) => {
  const { t } = useTranslation('common');
  return (
    <Tooltip text={t('missing-dynamic-data')}>
      {(tooltip) => (
        <span {...tooltip} className="text-gray-1">
          {'{' + placeholderData.friendlyName + '}'}
        </span>
      )}
    </Tooltip>
  );
};

export default memo(PlaceholderFiendlyName);
