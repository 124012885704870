import { createContext, FC, ReactNode, useContext } from 'react';
import FormActionWrapperV2 from '../components/form/renderer/FormActionWrapperV2';
import FormStepTitle from '../components/form/renderer/FormStepTitle';
import { FormActionWrapperProps, FormStepTitleProviderProps, FormStepWrapperProps } from './FormRendererDesignContextTypes';

export type FormRendererDesignContextType = {
  sectionWrapper: FC<FormStepWrapperProps> | string;
  sectionTitleProvider: FC<FormStepTitleProviderProps> | string;
  actionWrapper: FC<FormActionWrapperProps> | string;
  slots: {
    sectionTitleStart?: ReactNode;
    sectionTitleEnd?: ReactNode;
  };
};

export const FormRendererDesignDefaults: FormRendererDesignContextType = {
  actionWrapper: FormActionWrapperV2,
  sectionTitleProvider: FormStepTitle,
  sectionWrapper: 'div',
  slots: {},
};

export const FormRendererDesignContext = createContext<FormRendererDesignContextType>(FormRendererDesignDefaults);
export const useFormRendererDesign = (): FormRendererDesignContextType => useContext(FormRendererDesignContext);
