import { useEffect, useMemo, useState } from 'react';
import { FileInfo } from './file-upload/FileInfo';
import FileService from '../../services/FileService';
import { FCWithChildren } from '../../types/FCWithChildren';
import { FileUtils } from '../../utils/FileUtils';
import { FileUploadType } from './file-upload/FileuploadType';
import { useWarnOutboundLink } from '../../hooks/useWarnOutboundLink';

type Props = {
  file: FileInfo | { id: string; name: string };
} & ({ download?: false } | { download: true; addExtensionToDownload?: boolean });

const FileLink: FCWithChildren<Props> = (props) => {
  const { file, download, children } = props;
  const addExtensionToDownload = props.download && props.addExtensionToDownload;

  const [fileBlob, setFileBlob] = useState<Blob | null>(null);
  const [url, setUrl] = useState<string | null>(null);

  const externalLinkWarner = useWarnOutboundLink();

  useEffect(() => {
    if (url || fileBlob) return;

    if ('type' in file && file.type === FileUploadType.Link) {
      if (!file.url) return;

      let link = file.url;
      if (!file.url.match(/^https?:\/\//i)) {
        link = `http://${file.url}`;
      }

      setUrl(link);
    } else {
      FileService.getFile(file.id).then((fileBlob) => {
        setFileBlob(fileBlob);

        const url = URL.createObjectURL(fileBlob);
        setUrl(url);
      });
    }
  }, [file, fileBlob, url]);

  useEffect(() => {
    return () => {
      if (url && fileBlob) {
        URL.revokeObjectURL(url);
      }
    };
  }, [fileBlob, url]);

  const aProps = useMemo(() => {
    if (download) {
      if (!fileBlob) return {};
      const exntension = addExtensionToDownload ? `.${FileUtils.getFileExtensionFromMimeType(fileBlob.type)}` : '';
      return {
        download: file.name + exntension,
      };
    } else {
      return {
        target: '_blank',
      };
    }
  }, [download, file.name, fileBlob, addExtensionToDownload]);

  return (
    <>
      <a href={url || undefined} {...aProps} onClick={externalLinkWarner.onClick}>
        {children}
      </a>
      {externalLinkWarner.modal}
    </>
  );
};

export default FileLink;
